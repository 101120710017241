import Vue from 'vue';
import Vuex from 'vuex';
let webScoketIp=process.env.VUE_APP_API_WEBSOCKET_URL;
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    webSocket: null,
    moduleName: ''
  },
  mutations: {
    setModuleName(state, moduleName) {
      state.moduleName = moduleName;
    },
    setWebSocket(state, webSocket) {
      state.webSocket = webSocket;
    }
  },
  actions: {
    createWebSocket({ commit },userId) {

      const webSocket = new WebSocket(webScoketIp+"/"+userId);
      commit('setWebSocket', webSocket);
    }
  }
});
 
export default store;