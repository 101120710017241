<template>
  <div id="app">
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {

  }
};
</script>

<style>
html {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}
</style>