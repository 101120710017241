import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/iconfont/iconfont.css'
import * as echarts from "echarts";
import VueSignaturePad from 'vue-signature-pad'
import store from './js/store.js'; 
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'; // 引入 Swiper 的 CSS 文件

Vue.use(VueAwesomeSwiper);
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueSignaturePad)
Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    const ariaEls = el.querySelectorAll('.el-radio__original')
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden')
    })
  }
})
new Vue({
  store,
  router,
  render: function (h) { return h(App) }
}).$mount('#app')


